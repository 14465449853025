import React from "react";
import { Waypoint } from "app/components/Waypoint";
import Loader from "app/components/Loader";
import { useVideoUploadsPublishedQuery } from "services/graphql";
import Flex from "app/components/Flex";
import { UserVideoPost } from "app/components/UserVideos/UserVideoPost";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";

const PER_PAGE = 10;

export function UserVideoList() {
  const { data, loading, fetchMore } = useVideoUploadsPublishedQuery({
    variables: {
      pagination: {
        first: PER_PAGE,
      },
    },

    notifyOnNetworkStatusChange: true,
  });

  if (!data) {
    return <LoaderFullCentered />;
  }

  if (!data.videoUploadsPublished) {
    // TODO: Render empty state
    return <></>;
  }

  const { edges: videoEdges, pageInfo } = data.videoUploadsPublished;

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Flex
          position="relative"
          width="100%"
          justifyContent="center"
          flexDirection="column"
          gap="16px"
          pb="100px"
        >
          {videoEdges.map(({ node }) => (
            <Flex key={node.id}>
              <UserVideoPost userVideoData={node} />
            </Flex>
          ))}
          {!loading && (
            <Waypoint
              hasMore={pageInfo?.hasNextPage}
              fetchData={() => {
                fetchMore({
                  variables: {
                    pagination: {
                      first: PER_PAGE,
                      after: pageInfo?.endCursor,
                    },
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    const previousEntry = previousResult.videoUploadsPublished;
                    const newEdges =
                      fetchMoreResult.videoUploadsPublished.edges;

                    return {
                      videoUploadsPublished: {
                        ...fetchMoreResult.videoUploadsPublished,
                        edges: [...previousEntry.edges, ...newEdges],
                      },
                    };
                  },
                });
              }}
            />
          )}
        </Flex>
        {loading && (
          <Flex width="100%" justifyContent="center">
            <Loader width="25" />
          </Flex>
        )}
      </Flex>
    </>
  );
}
