import React from "react";
import styled from "styled-components";
import LoaderCentered from "./LoaderCentered";

const LoadingWrapper = styled.div`
  display: flex;
  height: -webkit-fill-available;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default props => (
  <LoadingWrapper>
    <LoaderCentered {...props} />
  </LoadingWrapper>
);
